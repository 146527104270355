
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList'
import { ROUTES } from '../../../constant/routes'
import { Link } from 'react-router-dom'
import * as Movieaction from '../../../actions/MovieAction'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useState } from 'react'


export default function Highlights(props) {

    const dispatch = useDispatch()
    const [data, setData] = useState()

    const { gethighlightdata } = bindActionCreators(Movieaction, dispatch)


    useEffect(() => {
        (async () => {
            let localArr = []
            const resData = await gethighlightdata()
            console.log("first", resData)
            if (resData?.data?.length) {
                resData?.data?.map((data) => {
                    if (data?.movie?.platform !== "App") {
                        localArr.push(data)
                    }
                })
            }
            setData(localArr)
        })();
    }, [])

    return (
        <>

            <div className='mt-5 mb-3'>
                {/* {/ <Container> /} */}
                {
                    data?.map((data, index) => (
                        <div key={index} style={{ border: "3px solid #0e0e0e", padding: "15px", borderRadius: "1rem", boxShadow: "16px 21px 20px rgba(255, 255, 255, 0.1)" }}>
                            <Link to={data?.video_type == "Movie" ? `${ROUTES.moviedetails}/${data?.movie?.slug}` : `${ROUTES.seriesDetails}/${data?.series?.slug}`}>
                                <div className='my-highlights1' >
                                    <div style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', display: 'inline-block' }}>
                                        <video
                                            width="100%"
                                            height="300"
                                            controlsList="nodownload"
                                            muted
                                            controls={false}
                                            loop
                                            autoPlay={true}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', display: 'inline-block'
                                            }}
                                        >
                                            <source
                                                src={(data?.video_type == "Movie" ? data?.movie?.free_preview : data?.series?.free_preview)}
                                                type="video/mp4" />
                                        </video>
                                    </div>
                                    <div>
                                        {/* {/ <img style={{ width: '200px' }} src={IMAGE_ENDPOINT + (data?.video_type=="Movie" ? data?.movie?.movie_logo : data?.series?.series_logo)} alt="Logo" /> /} */}
                                        <h4>{data?.video_type == "Movie" ? data?.movie?.movie_name : data?.series?.series_name}</h4>
                                        <p className='mt-2' dangerouslySetInnerHTML={{ __html: (data?.video_type == "Movie" ? (data?.movie?.movie_description?.length <= 200 ? data?.movie?.movie_description : data?.movie?.movie_description.substring(0, 200) + "...") : (data?.series?.series_description?.length <= 250 ? data?.series?.series_description : data?.series?.series_description.substring(0, 200) + "...")) }}></p>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                            <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                <p>
                                                    <b>Language: </b><span className='span-tag'>{data?.video_type == "Movie" ? data?.movie?.movie_language : data?.series?.language?.language_name}</span>
                                                </p>
                                                {data?.video_type == "Movie" &&
                                                    <p style={{ marginTop: "-0.5rem" }}>
                                                        <b>Duration: </b><span className='span-tag'>{data?.movie?.movie_duration}</span>
                                                    </p>
                                                }
                                                {/* {data?.video_type == "Movie" &&
                                                    <p style={{ marginTop: "-0.5rem" }}>
                                                        <b>Starring: </b><span className='span-tag'>
                                                            {
                                                                data?.video_type == "Movie" ? data?.movie?.movie_cast?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.cast_name}</span>)) : <>ddd</>
                                                            }
                                                        </span>
                                                    </p>
                                                } */}


                                            </div>
                                            <div style={{ display: "flex" }}>
                                                {/* <div className='box-highlights' style={{ fontSize: "22px" }}>
                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                </div> */}
                                                <div className=' ml-1' style={{ fontSize: "22px" }}>
                                                    {/* {/ <i className="fa fa-info-circle" aria-hidden="true"></i> /} */}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <button className="btn btn-hover iq-button">
                                <i className="fa fa-play mr-2" aria-hidden="true"></i>More
                            </button> */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                }

                {/* {/ </Container> /} */}
            </div>


        </>
    )
}


















































// import React from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react';

// import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
// import { EffectCoverflow } from 'swiper';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



// const Highlights = () => {
//     return (
//         <div style={{ marginTop: "8rem", marginBottom: "8rem" }}>
//             <Swiper
//                 effect={'coverflow'}
//                 grabCursor={true}
//                 centeredSlides={true}
//                 loop={true}
//                 slidesPerView={1.37}
//                 coverflowEffect={{
//                     rotate: 12,
//                     stretch: 155,
//                     depth: 400,
//                     modifier: 2.8,
//                 }}
//                 autoplay={{
//                     delay: 6000,
//                 }}
//                 speed={650}
//                 pagination={{ el: '.swiper-pagination', clickable: true }}
//                 navigation={{
//                     nextEl: 'swiper-buton-next',
//                     prevEl: 'swiper-button-pre',
//                     clickable: true
//                 }}
//                 modules={[EffectCoverflow, Pagination, Navigation]}
//                 className='swiper-container'
//             >
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://lh6.googleusercontent.com/proxy/_VI03Y4GTvvUKdkg6s--wZbJFCWlNqKpuGK8SvTu-Wqaxkc9v-68AGtJWKD6OsM0T73lrtMVr2JecQdTyAWsPW1K50b3KgftZg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://wowslider.com/sliders/demo-51/data1/images/car.jpg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://soliloquywp.com/wp-content/uploads/2013/05/action-backlit-beach-1046896-1200x450_c.jpg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://soliloquywp.com/wp-content/uploads/2013/05/camera-mountains-person-6745-1200x450_c.jpg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://wowslider.com/sliders/demo-51/data1/images/car.jpg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://soliloquywp.com/wp-content/uploads/2013/05/camera-mountains-person-6745-1200x450_c.jpg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://lh6.googleusercontent.com/proxy/_VI03Y4GTvvUKdkg6s--wZbJFCWlNqKpuGK8SvTu-Wqaxkc9v-68AGtJWKD6OsM0T73lrtMVr2JecQdTyAWsPW1K50b3KgftZg" alt="" />
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <img style={{ height: "550px" }} src="https://soliloquywp.com/wp-content/uploads/2013/05/action-backlit-beach-1046896-1200x450_c.jpg" alt="" />
//                 </SwiperSlide>


//             </Swiper>
//         </div >
//     )
// }

// export default Highlights