import React, { useEffect, useState } from 'react';

const VideoFile123 = (props) => {
  const [time, setTime] = useState(0);
  const[preview,setPreviw] = useState(props.data)

  const { getCurrentTime } = props;

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = getCurrentTime(); // Get current time on each interval
      setTime(decreaseTime(preview, currentTime)); // Pass current time to decreaseTime
    }, 1000);

    return () => clearInterval(timer);
  }, [ getCurrentTime]);

  const decreaseTime = (timeString, currentTime) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    let totalSeconds = hours * 3600 + minutes * 60 + seconds;
    let remainingSeconds = totalSeconds - currentTime;

    // Ensure remaining time doesn't go negative
    if (remainingSeconds < 0) {
      remainingSeconds = 0;
    }

    const newHours = Math.floor(remainingSeconds / 3600);
    const newMinutes = Math.floor((remainingSeconds % 3600) / 60);
    const newSeconds = remainingSeconds % 60;

    return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div><span  style={{color:"orange"}}>Free Preview </span>: {time}</div>
  );
};

export default VideoFile123;
