import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import * as HomeAction from '../../../actions/HomeActions'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCreative, Thumbs } from "swiper";
import { CircularProgress } from '@mui/material';
import { EffectCoverflow } from 'swiper';


function Slider() {
    const playerRef = React.useRef(null);
    const dispatch = useDispatch()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [save, setSave] = useState(false)
    const { getSliderData } = bindActionCreators(HomeAction, dispatch)
    const history = useHistory()
    const [sliderData, setSliderData] = useState([])
    const [count, setCount] = useState(-2);
    SwiperCore.use([Autoplay, EffectCoverflow, Thumbs])
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

    useEffect(() => {
        (async () => {
            let localArr = []
            let userObj = {
                profile_id: profile_id ? profile_id : "null",
                user_id: user_id || "null"
            }
            const sliderRes = await getSliderData(userObj)
            sliderRes?.length && sliderRes?.map((data) => {
                if (data?.movie?.platform !== "App") {
                    localArr.push(data)
                }
            })
            setSliderData(localArr)
        })();
    }, [save])
  
    const handleRedirect = (slug) => {
        history.push(`${ROUTES.moviedetails}/${slug}`)
    }

    return (
        <>
            {
                sliderData?.length ?
                    <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={1.17}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 150,
                                depth: 200,
                                modifier: 1.8,
                            }}

                            initialSlide={1}
                            pagination={{ el: '.swiper-pagination', clickable: true }}
                            navigation={{
                                nextEl: 'swiper-buton-next',
                                prevEl: 'swiper-button-pre',
                                clickable: true
                            }}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className='swiper-container '


                            autoplay={{
                                delay: 3500,
                            }}
                            onSlideChange={(swiper) => {

                                if (sliderData[count]?.slider_type == "Video") {

                                    swiper.autoplay.stop();
                                    playerRef.current.src(sliderData[count]?.video_url)
                                    playerRef.current.play()
                                    playerRef.current.on('ended', () => {
                                        swiper.autoplay.start();
                                    });

                                }
                                if (count == sliderData?.length - 1)
                                    setCount(-1);
                                else {
                                    setCount((prev) => prev + 1);
                                }
                            }}
                            style={{ marginTop: "70px", marginBottom: "1rem" }}
                        >
                            {
                                sliderData?.map((data, index) => (

                                    <SwiperSlide
                                        // className="slide slick-bg"
                                        key={index}
                                        style={{ backgroundSize: "cover" }}
                                        onClick={() => handleRedirect(data?.movie?.slug)}
                                    >
                                        <img className='home-slider-res' src={IMAGE_ENDPOINT + (data?.web_banner || data?.movie?.movie_poster)} alt="" />

                                    </SwiperSlide>
                                ))
                            }


                        </Swiper>
                    </section>
                    :
                    <div style={{ height: "80vh" }}>
                        <div style={{display:"flex",justifyContent:"center",top:"50%",position:"relative" }}>
                            <CircularProgress style={{ color: "#ff7f30" }} />
                        </div>
                    </div>
            }

        </>
    )
}
export default Slider