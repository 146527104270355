import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { user_details_web } from '../../../../actions/HomeActions';
import { ROUTES } from '../../../../constant/routes';
import logo from '../../../../assets/regal_images/regal_logo.png'
import StarsIcon from '@mui/icons-material/Stars';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DevicesIcon from '@mui/icons-material/Devices';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Grid, Popover, Tooltip, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import giftImg from '../../../../assets/regal_images/gift.png'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Share from '../../common/Share';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon
} from "react-share";

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const My_Account = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    console.log(user, "acc")
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const user_details = useSelector((state) => state.home.user_details?.data)
    const user_plan_expired = useSelector((state) => state.home.user_details)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    useEffect(() => {

        dispatch(user_details_web({ user_id: user?.id }))

    }, [])
    console.log(user_plan_expired, "my_account")

    const handleClick = () => {
        history.push('/profile_selection', { back: true })
    }
    

    return (
        <>
            <section className="m-profile set-margin-responsive1 setting-wrapper">
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col lg="4" className="">
                            <h5 className="main-title mb-4">My Account</h5>

                            <div className="sign-user_card text-center">
                                <img style={{ height: "150px" }} src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                                <h4 className="mb-3">{user_details?.firstName + " " + user_details?.lastName}</h4>
                                <p style={{ display: "flex", alignItems: "center" }}><LocationOnIcon /> <span>{user_details?.userLocation}</span></p>
                                <div onClick={(event) => anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)} style={{ cursor:"pointer",border: "1px solid grey", padding: "10px", borderRadius: "1rem" }}>
                                    <div style={{ textAlign: "center", fontSize: "27px" }}>
                                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                                        <Popover
                                            id={id}
                                            className='mt-2'
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}

                                        >
                                            <Typography sx={{ p: 2 }}>
                                                <div className="mt-1" >
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <img src={logo} style={{ width: "100px" }}></img>
                                                    </div>
                                                    <hr style={{ color: "white" }} />
                                                    <p style={{ color: "white" }}>Invite your friends via</p>
                                                    <TwitterShareButton url={props?.sharedURL}>
                                                        <TwitterIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </TwitterShareButton>

                                                    <FacebookShareButton url={props?.sharedURL}>
                                                        <FacebookIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </FacebookShareButton>

                                                    <TelegramShareButton url={props?.sharedURL}>
                                                        <TelegramIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </TelegramShareButton>

                                                    <WhatsappShareButton url={props?.sharedURL}>
                                                        <WhatsappIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </WhatsappShareButton>

                                                    <LinkedinShareButton url={props?.sharedURL}>
                                                        <LinkedinIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </LinkedinShareButton>

                                                    <EmailShareButton url={`Join Gujju family and use ${user_details?.userCode} code to get exclusive discount on subscription plan. Warm Regards, gujjustream.com`}>
                                                        <EmailIcon size={35} round={true} style={{ margin: "5px" }} />
                                                    </EmailShareButton>
                                                
                                                </div>
                                            </Typography>
                                        </Popover>
                                    </div>
                                    <p style={{ display: "flex", alignItems: "center" }}><span>Invite your friends to join our platform and use this code to get exclusive discounts on his/her subscription plan.</span></p>
                                    <Tooltip title="Click to copy">
                                        <p style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}><ContentCopyIcon /> <span>{user_details?.userCode}</span></p>
                                    </Tooltip>
                                    
                                </div>

                            </div>
                        </Col>
                        <Col lg="8">

                            <div className='mb-2 mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h5 className="main-title">Personal Details</h5>
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>


                                    <Link to={ROUTES.edit_account}>
                                        <EditIcon style={{ width: "44px", height: "37px", padding: "3px", borderRadius: "5px", color: "#ffffff", border: "1px solid #ffffff" }} />
                                    </Link>
                                </div>
                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        Email
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user_details?.email}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Mobile No.
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user_details?.mobileNumber}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Date of Birth
                                    </Grid>
                                    <Grid item xs={8}>
                                        {new Date(user_details?.birthDate).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={6} >
                                        <Link to="/change_password">
                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <LockOpenIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Change Password</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <div onClick={handleClick} style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem", cursor: "pointer" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <GroupIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Manage Profiles</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link to="/transaction_history">

                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <ReceiptLongIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Transaction History</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link to="/device_management">

                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <DevicesIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Device Management</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div onClick={() => history.push(ROUTES.rewards)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <StarsIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Manage Coins</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div onClick={() => history.push(ROUTES.qrdetail)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <img src={giftImg} alt='acc' className='menu-img' />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Offers & Rewards</span>
                                            </Grid>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(My_Account)