import React, { useEffect, useState } from 'react'
import Slider from './Slider'
import ContinueWatching from './ContinueWatching'
import PopularMovies from './PopularMovies'
import Genres from './Genres'
import RecommendedMovies from './RecommendedMovies'
import MovieBanner from './MovieBanner'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import * as HomeAction from '../../../actions/HomeActions'
import * as MovieAction from '../../../actions/MovieAction'
import UpcomingMovies from './UpcomingMovies'
import Category from './Category'
import WebSeries from './WebSeries'
import Language from './Language'
import RemainingCategory from './RemainingCategory'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import logo_gif from "../../../assets/regal_images/gif_load.gif"
import { Box, Checkbox, CircularProgress, FormControlLabel, Modal, Typography } from '@mui/material'
import Artist from './Artist'
import LeavingSoon from './LeavingSoon'
import { Base64 } from 'js-base64'
import Highlights from './Highlights'
import GoogleAds from './GoogleAds'



const styleforAlert = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "90vh",
  overflow: "scroll",
  overflowX: "hidden",
  width: "90%",
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,
}

export default function Home() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
  const { getAllSubacategory } = bindActionCreators(MovieAction, dispatch)
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const login_details = localStorage.getItem("user")
  const isLoggedIn = sessionStorage.getItem('isLoggedIn')
  const [home, setHome] = useState()
  const [loaderCompletion, setLoaderCompletion] = useState(false)
  const [counter, setCounter] = useState(0);
  const [subCat, setSubCat] = useState([])
  const [subCat1, setSubCat1] = useState([])
  const [remainingCat, setRemainingCat] = useState([])
  const [alert, setAlert] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const getSignal = sessionStorage.getItem("AgreeWithDisclaimer")

  useEffect(() => {
    (async () => {
      if (!getSignal) {
        if (!loaderCompletion && !login_details) {

          setTimeout(() => {
            sessionStorage.setItem('logoDisplayedBefore', 'true');

            setAlert(true)

          }, 5000)
        }
        else {
          setAlert(true)

        }
      }
      const resData = await getAllSubacategory()
      // console.log("subcategory1111", resData)
      setSubCat(resData?.data?.subcategory1)
      setSubCat1(resData?.data?.subcategory2)
      setRemainingCat(resData?.data?.remaining_subcategory)
    })();
  }, [])


  useEffect(() => {
    setTimeout(() => { setLoaderCompletion(true) }, 5000)
    console.log("isLoggedIn", isLoggedIn)
    if (login_details && !isLoggedIn) {
      setLoaderCompletion(true)
      sessionStorage.setItem("user", login_details)
      sessionStorage.setItem("isLoggedIn", true)
      // setTimeout(() => {
      history.push("/profile_selection")
      // }, 1000)

      // dispatch(HomeAction.login(JSON.parse(login_details), history))
    }
    (async () => {
      let userObj = {
        user_id: user_id ? user_id : "null",
        profile_id: profile_id ? profile_id : "null"
      }
      const resData = await getAllHomeData(userObj)
      // setHome(resData?.data)
      setHome({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    })()
  }, [isLoggedIn])

  useEffect(() => {
    const updateCounter = () => {
      setCounter((prevCounter) => prevCounter + 1);
    };
    const timerId = setTimeout(updateCounter, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, [counter]);

  useEffect(() => {
    const logoDisplayedBefore = sessionStorage.getItem('logoDisplayedBefore');
    if (logoDisplayedBefore) {
      setLoaderCompletion(true);
    } else {
      sessionStorage.setItem('logoDisplayedBefore', 'true');
    }
  }, []);


  const handlechecked = (e) => {
    console.log(e.target.checked)
    setIsChecked(e.target.checked)
  }

  const handlesession = () => {
    sessionStorage.setItem("AgreeWithDisclaimer", true)
    setAlert(false)
  }

  return (
    <>

      <div style={{ opacity: loaderCompletion ? "1" : "0" }}>
        <Slider />

        <ContinueWatching />
        <RecommendedMovies />
        {/* <UpcomingMovies upComingMovieData={home?.data?.upcoming_movie} /> */}
        <LeavingSoon />
        {
          subCat ? subCat?.map((data, index) => (
            <div>
              {
                index == 1 &&
                <>
                  <MovieBanner promotion={home?.data?.Promotion} />
                  {/* <GoogleAds/> */}
                </>

              }
              {
                index == 2 &&
                <Highlights />

              }
              <Category category={data} />
            </div>
          ))
            :
            <h4 style={{ textAlign: "center", marginTop: "8rem" }}></h4 >
        }
        {/* <Category category={home?.data?.Subcategory1?.[0]} /> */}



        {/* <MovieBanner promotion={home?.data?.Promotion} /> */}

        {/* <Category category={home?.data?.Subcategory1?.[1]} /> */}
        {
          subCat1?.map((data, index) => (
            <div key={index}>
              {
                index == 0 &&
                <WebSeries />

              }
              <Category category={data} />
            </div>

          ))
        }

        {
          remainingCat?.map((data, index) => (
            <Category category={data} />
          ))
        }

        <Modal
          open={alert}
          // onClose={() => setAlert(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box className="my-responsive-class remove-scroll" sx={styleforAlert}>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <img src={logo} width={"100px"} /> */}
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left", fontSize: "20px", fontWeight: "900" }}>
                Disclaimer
              </Typography>
            </div>
            <hr />

            <div className="login-popup" >
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                All content, including text, images, graphics, and other materials found on this site (or in this document) are protected by copyright law and are the property of their respective owners.  The use of such Third-Party Content is intended for informational and educational purposes only and is believed to fall under the "fair use" provisions of copyright law. However, all rights remain with the respective copyright holders.
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left", fontWeight: "900" }}>
                Use of Third-Party Content:<br />
                Attribution:
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                All Third-Party Content used on this site/document is credited to the original author and/or owner wherever possible. Proper attribution is provided to acknowledge the source of the content.
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left", fontWeight: "900" }}>
                Purpose and Scope:
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                The inclusion of Third-Party Content is for the purposes of commentary, criticism, news reporting, teaching, scholarship, or research. The use of such content is not intended to infringe upon the rights of the original copyright holders.
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left", fontWeight: "900" }}>
                Disclaimer of Liability:
              </Typography>
              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                The author and/or owner of this site/document do not claim ownership of any Third-Party Content. All Third-Party Content remains the property of its respective owners. The author and/or owner are not responsible for any inaccuracies or errors in the Third-Party Content, nor for any loss or damage resulting from the use of such content.            </Typography>

              <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                If you believe that any content on this site (or in this document) infringes your copyright, please contact us immediately so that we can address your concerns appropriately.            </Typography>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.5rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox onClick={(e) => handlechecked(e)} value="true" name="remember_me" style={{ fontSize: "14px !important", color: "orange" }} />
                  }
                  label={<span style={{ fontSize: "14px !important", color: "orange" }}>I agree with the above disclaimers.</span>}
                />
                <button disabled={isChecked ? false : true} onClick={() => handlesession()} className={"btn btn-hover ml-3 iq-button"}>
                  Accept
                </button>
              </div>
            </div>
          </Box>
        </Modal>

      </div>
      <div style={{ display: !loaderCompletion ? "" : "none" }}>
        <div style={{ position: "absolute", top: "0", left: "0", zIndex: "999", margin: "auto", background: "#010101", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
            <img src={logo_gif} alt='load-gif' style={{ height: "30vh", display: "flex", alignSelf: "center" }} />
          </div>
        </div>
      </div>
    </>
  )
}
