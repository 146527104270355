import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Breadcrumb, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./style.css"
// //img
import subscriptionBG from '../../../../assets/images/subscriptionBG.jpg'
import LoadingGIF from '../../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import RazorpayButton from '../../../../razorpay/razorpayButton'
import { check_promocode } from '../../../../actions/PaymentActions'
import { PROMOCODE } from '../../../../Actiontype/ActionType'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SVOD from "./svod"
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { user_details_web } from '../../../../actions/HomeActions'
import { ROUTES } from '../../../../constant/routes'
import * as PaymentAction from '../../../../actions/PaymentActions'
import { load } from '@cashfreepayments/cashfree-js'
import axios from 'axios'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PricingPlan2 = () => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const user = JSON.parse(sessionStorage.getItem('user'))
    const [selectedPlan, setSelectedPlan] = useState({})
    const [form, setForm] = useState({ select_type: location.state?.categoryType || "Plan", movie: location.state?.movie, plan: "" })
    console.log("location", location?.state)
    const [checkoutPage, setCheckoutPage] = useState(false)
    const promocode = useSelector((state) => state.payment.promocode)
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_details = useSelector((state) => state?.home?.user_details?.data)
    const { paymentInitiateCashFree } = bindActionCreators(PaymentAction, dispatch)
    const { getPaymentStatus } = PaymentAction

    const [orderId, setOrderId] = useState(null)
    let ORDER = ""
    let cashfree;
    let initializeSDK = async function () {
        cashfree = await load({
            mode: "production"
            // mode: "sandbox"

        })
    }
    initializeSDK()


    useEffect(() => {
        dispatch(user_details_web({ user_id: user?.id }))
    }, [])

    useEffect(() => {
        console.log(location?.state)
        if (location.state?.transactionType == "TVOD")
            setCheckoutPage(true)
        else
            setCheckoutPage(false)
        setForm({ select_type: location.state?.categoryType || "Plan", movie: location.state?.movie, plan: "" })

    }, [location.state?.transactionType])
    const handleReset = () => {
        setForm({ ...form, promocode: "" })
        dispatch({ type: PROMOCODE, payload: null });
    }
    useMemo(() => {

        handleReset()
    }, [selectedPlan, location.state?.movie, location.state?.series])

    const handleRemoveCountryCode = (phoneNumber) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);

        if (parsedNumber) {
            const nationalNumber = parsedNumber.nationalNumber;
            return nationalNumber;
        }
    };



    const handleStripeRedirect = async () => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            // console.log("data call", selectedPlan, promocode, user_details)
            let userObj = {
                email: user_details?.email,
                name: user_details?.firstName + " " + user_details.lastName,
                mobile: handleRemoveCountryCode(user_details?.mobileNumber),
                amount: promocode ? promocode?.data?.final_amount : selectedPlan?.country_price?.[0]?.price,
                plan: selectedPlan?.id,
                transaction_type: "SVOD",
                currency: selectedPlan?.currency || 'aud',
                promocode: promocode?.data?.id || null,
                user_id: user_details?.id,
                gateway: "Cashfree"
            }
            // history.push(ROUTES.stripePayment, { paymentData: userObj })
            const res = await paymentInitiateCashFree(userObj)
            console.log(res)
            if (res?.statuscode == 200) {
                let sessionId = await res?.data?.payment_session_id
                setOrderId(res?.data?.order_id)
                ORDER = res?.data?.order_id
                let checkoutOptions = {
                    paymentSessionId: sessionId,
                    redirectTarget: "_modal",
                }

                cashfree.checkout(checkoutOptions).then(async (result) => {
                    console.log("payment initialize", result)
                    // let resData = await axios.post("https://api.gujjustream.com/api/payment_verify/", { order_id: orderId || ORDER })
                    const resData = await getPaymentStatus({ order_id: orderId || ORDER })
                    if (resData?.status?.payment_status == "SUCCESS") {
                        history.replace("/")
                    }
                    setOrderId(null);
                    ORDER = ""
                    console.log("verify status", resData)

                })



            }
        }

    }
    console.log(selectedPlan, "selectedPlan")
    const handleStripeRedirectTVOD = (price, adfree) => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            let userObj = {
                email: user_details?.email,
                name: user_details?.firstName + " " + user_details.lastName,
                mobile: user_details?.mobileNumber,
                amount: price,
                slug: location.state.redirect,
                plan: "",
                movie: location?.state?.movie || null,
                category_type: location?.state?.categoryType,
                adfree: adfree,
                transaction_type: "TVOD",
                currency: 'aud',
                series: location?.state?.series || null,
                promocode: promocode?.data?.id || null
            }
            // console.log(userObj)
            history.push(ROUTES.stripePayment, { paymentData: userObj })
        }

    }

    return (
        <>

            <div className="site-content-contain ">
                <div id="content" className="site-content">

                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <Row>
                                <Col lg="7" sm="12">
                                    {!checkoutPage ?
                                        <SVOD selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} setCheckoutPage={setCheckoutPage} /> :
                                        <div className="iq-pricing-card-two pt-5">
                                            <div className="table-responsive iq-pricing pt-2">

                                            </div>
                                            <div className="iq-price-bottom">

                                                <div className="checkout-page-table">
                                                    <Table id="my-table" className="table my-tbl" data-active="premium" >
                                                        <col></col>
                                                        <col style={{ background: "  rgb(0, 0, 0)", border: "3px solid  white" }}></col>
                                                        <thead>
                                                            <tr>
                                                                <th>{location.state?.categoryType || "Plan"} Name</th>
                                                                <td> {location.state?.movieName || location.state?.seriesName || selectedPlan?.plan_name}</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>{location.state?.transactionType == "TVOD" ? "Available for" : "Availability"}</th>
                                                                <td> {location.state?.transactionType == "TVOD" ? (location.state?.available_for + " days") : selectedPlan?.duration}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>{location.state?.transactionType == "TVOD" ? "Rental Price" : "Amount"}</th>
                                                                <td> {Number(location.state?.price || selectedPlan?.country_price?.[0]?.price).toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table></div>
                                                <hr style={{ backgroundColor: "white" }} />

                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        placeholder='Type Promocode Here'
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        className='promocode-input'
                                                        value={form?.promocode}
                                                        onChange={(e) => setForm({ ...form, promocode: e.target.value.toUpperCase() })}
                                                        isInvalid={promocode?.statuscode == 406}
                                                    />
                                                    <Button variant="outline-secondary" style={{ color: form?.promocode && "white" }} onClick={() => {
                                                        console.log({ ...form, plan: selectedPlan?.id })
                                                        dispatch(check_promocode({ ...form, plan: selectedPlan?.id }))
                                                    }} disabled={form.promocode ? false : true} id="button-addon2" className='promocode-button'>
                                                        Apply
                                                    </Button>
                                                    <Form.Control.Feedback type="invalid">
                                                        {promocode?.message}
                                                    </Form.Control.Feedback>
                                                </InputGroup>

                                                {promocode?.statuscode == 200 && <p className='promocode-block'><span><i className="fa fa-check" aria-hidden="true"></i>&nbsp;{promocode?.data?.promocode}</span> Applied <span>${Number(promocode?.data?.discount_amount).toFixed(2)}</span> off<span style={{ float: "right", cursor: "pointer" }} onClick={() => handleReset()}><i className="fa fa-trash" aria-hidden="true"></i> </span></p>}
                                                {/* <hr style={{ backgroundColor: "white" }} />
                                                <p style={{ width: "100%", padding: "0 2rem", fontSize: "24px", color: "white" }}>Pay<span style={{ float: "right" }}> ${promocode?.data?.final_amount || location.state?.price || selectedPlan?.country_price?.[0]?.price}</span></p> */}

                                                {/* <div className="align-items-center r-mb-23" style={{ display: "flex", justifyContent: "center", gap: "10rem" }} data-animation-in="fadeInUp" data-delay-in="1.3">
                                                    <div className={""} onClick={() => location.state?.transactionType == "TVOD" ? history.goBack() : setCheckoutPage(prev => !prev)}>Go back</div>
                                                    <RazorpayButton className={"btn btn-hover iq-button"} title={"Pay Now : $" + Number(promocode?.data?.final_amount || location.state?.price || selectedPlan?.country_price?.[0]?.price).toFixed(2)} transactionType={location.state?.transactionType || "SVOD"} promocode={promocode?.data?.id} plan={selectedPlan?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={promocode?.data?.final_amount || location.state?.price || selectedPlan?.country_price?.[0]?.price} />
                                                </div> */}
                                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <div>
                                                        {location.state?.transactionType == "TVOD" ?
                                                            <div>
                                                                {
                                                                    location?.state?.ads?.length ?
                                                                        <div className='d-flex' style={{ gap: "2rem" }}>
                                                                            {/* <RazorpayButton className={"btn btn-hover iq-button text_small"} title={"Pay : ₹" + Number(promocode?.data?.final_amount || location.state?.price).toFixed(2) + " (with Ads)"} transactionType={"TVOD"} promocode={promocode?.data?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={promocode?.data?.final_amount || location.state?.price} ad_free={"false"} /> */}
                                                                            <button onClick={() => handleStripeRedirectTVOD(Number(promocode?.data?.final_amount || location.state?.price), false)} className={"btn btn-hover iq-button"}>{"Pay : $" + Number(promocode?.data?.final_amount || location.state?.price).toFixed(2) + " (with Ads)"}</button>

                                                                            {/* <RazorpayButton className={"btn btn-hover iq-button text_small"} title={"Pay : ₹" + Number(promocode?.data?.final_amount || location.state?.adfreePrice).toFixed(2) + " (Ad Free)"} transactionType={"TVOD"} promocode={promocode?.data?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={promocode?.data?.final_amount || location.state?.adfreePrice} ad_free={"true"} /> */}
                                                                            <button onClick={() => handleStripeRedirectTVOD(Number(promocode?.data?.final_amount || location.state?.adfreePrice), true)} className={"btn btn-hover iq-button"}>{"Pay : $" + Number(promocode?.data?.final_amount || location.state?.adfreePrice).toFixed(2) + " (Ad Free)"}</button>

                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className='d-flex' style={{ gap: "2rem" }}>
                                                                                {/* <RazorpayButton className={"btn btn-hover iq-button text_small"} title={"Pay : ₹" + Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.adfreePrice || location.state?.price)).toFixed(2)} transactionType={"TVOD"} promocode={promocode?.data?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.adfreePrice || location?.state?.price)} ad_free={"true"} /> */}
                                                                                <button onClick={() => handleStripeRedirectTVOD(Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || location.state?.price), true)} className={"btn btn-hover iq-button"}>{"Pay : $" + Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || location.state?.price).toFixed(2)}</button>

                                                                            </div>
                                                                        </div>
                                                                }

                                                            </div>
                                                            :
                                                            // <RazorpayButton className={"btn btn-hover iq-button"} title={"Pay Now : ₹" + Number(promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price).toFixed(2)} transactionType={"SVOD"} promocode={promocode?.data?.id} plan={selectedPlan?.id} price={promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price} />
                                                            <div>
                                                                <button onClick={() => handleStripeRedirect()} className={"btn btn-hover iq-button"}>{`Pay Now : ${selectedPlan?.country_currency_symbol || "$"}` + Number(promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price).toFixed(2)}</button>
                                                            </div>
                                                        }
                                                    </div>

                                                </div><br />
                                                <div style={{ cursor: "pointer", textAlign: "center" }} onClick={() => location.state?.transactionType == "TVOD" ? history.goBack() : setCheckoutPage(prev => !prev)}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Go back</div>

                                            </div>
                                        </div>}
                                </Col>
                                <Col lg="5" sm="12">
                                    <div className='marquee'>
                                        <img src={subscriptionBG} width={"100%"} />
                                        <img src={subscriptionBG} width={"100%"} />

                                    </div>
                                    <div className='overlay-shadow'>
                                        <img src={LoadingGIF} width={"200px"} />
                                        <p className='mt-3'>{location.state?.transactionType == "TVOD" ? "Buy" : "Subscribe"} now and start streaming</p>
                                    </div>
                                </Col>


                            </Row>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PricingPlan2;