import React, { useEffect, useState, useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import "videojs-markers"
import { useDispatch, useSelector } from 'react-redux';
// import { advertise_seen_count_change } from '../actions/MovieAction';
import { bindActionCreators } from 'redux';
import * as MovieAction from '../actions/MovieAction'
import left_arrow from "../assets/regal_images/left-arrow.png"
import logo from '../assets/regal_images/regal_logo.png'

import image1 from '../assets/regal_images/qr_code.jpg'
// import image2 from '../assets/regal_images/qr-code1.png'
// import image3 from '../assets/regal_images/qr-code2.png'


import axios from "axios";
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { RUNNING_STATUS } from '../Actiontype/ActionType';
import * as HomeAction from '../actions/HomeActions';
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { ROUTES } from '../constant/routes';
// import scratchcardimage from '../assets/regal_images/scratchcard.png'
import scratchcardimage from '../assets/regal_images/scratchcard1.png'
import { NavLink } from 'react-router-dom'
import scratchgif from '../assets/regal_images/scratchgif.gif'
import VideoFile123 from './VideoFile123';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoFile() {



  const playerRef = React.useRef(null);
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [movieData, setMovieData] = useState(location?.state?.movie)
  const [adsFlag, setAdsFlag] = useState(location?.state?.movie?.advertise?.map((ele) => true) || [])
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const { addContinueWatching } = bindActionCreators(MovieAction, dispatch)
  const { change_running_status, getMovieDetails } = bindActionCreators(HomeAction, dispatch)
  const { live_movie_view_count, advertise_seen_count_change } = MovieAction
  const [remainingMin, setRemainingMin] = useState(false)

  const running_status = useSelector((state) => state.home.running_status)
  const [popupOpen, setPopupOpen] = useState(false)
  const [ip, setIp] = useState("")

  const [open, Setopen] = useState(false)
  const { getqrdetail } = bindActionCreators(MovieAction, dispatch);
  const { qrredeemhistory } = bindActionCreators(MovieAction, dispatch);
  const [qrdata, setQrdata] = useState([]);


  // const data = useSelector((state)=> state)



  const handleClose = () => {
    Setopen(false);
    history.replace(ROUTES.moviedetails + "/" + location?.state?.movie?.Data?.slug)
    // dispatch({ type: RUNNING_STATUS, payload: null })
  }


  useEffect(() => {
    (async () => {
      // const resData = await getqrdetail()
      // setQrdata(resData?.data?.data)
    })();
  }, [])







  useEffect(() => {
    (async () => {
      if (location?.state) {

        setMovieData(location?.state?.movie)

        console.log("tempAdsFlag", location?.state)
        const res = await axios.get("https://api.ipify.org/?format=json");
        if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
          const result = await change_running_status({
            user: user_id,
            login_type: "Web",
            running_status: "Running",
            ip_address: res.data.ip,
            access_type: location?.state?.movie?.Data?.movie_access,
            movie_id: location?.state?.movie?.Data?.id,
            episode_id: "",
            content_type: location?.state?.movie?.category_type
          })
        }

        // dispatch(change_running_status({ user: user_id, login_type: "Web", running_status: "Running", ip_address: res.data.ip }))
        setIp(res.data.ip)

      }
    })()

  }, [location?.state])

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (!playerRef.current) return;

      const player = playerRef.current;
      console.log("event.key",event.key)
      switch (event.key) {
        case 'ArrowRight':
          player.currentTime(player.currentTime() + 10); // Forward 10 seconds
          break;
        case 'ArrowLeft':
          player.currentTime(player.currentTime() - 10); // Backward 10 seconds
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useMemo(async () => {
    // setAdsFlag(movieData?.advertise?.map((ele) => true))
    if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
      let userObj = {
        movie: location?.state?.movie?.Data?.id,
        status: "Running"
      }
      const send_status = await live_movie_view_count(userObj)
    }


  }, [location?.state?.movie?.Data?.id])

  useMemo(async () => {
    setAdsFlag(location?.state?.movie?.advertise?.map((ele) => true) || [])


  }, [movieData])


  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    title: movieData?.Data?.movie_name,
    textTrackSettings: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    userActions: { hotkeys: true },
    controlBar: {
      qualitySelector: true,
      fullscreenToggle: true,
      volumePanel: { inline: false },
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10
      }

    },


    tracks: movieData?.subtitle?.map((ele) => ({ src: ele?.subtitle_file, kind: 'subtitles', srclang: 'en', label: ele?.subtitle_language })),
    audio: movieData?.audio?.map((ele) => ({
      id: ele?.id,
      kind: 'alternative',
      label: ele?.audio_language,
      language: ele?.audio_language,
      audio: ele?.audio_file
    })),

    // tracks: [{ src: '', kind: 'captions', srclang: 'en', label: 'English' }],
    sources: [
      {
        src: `${movieData?.Data?.movie_link}`,
        type: 'application/x-mpegURL',
      }
    ]

  };
  const handleAdvertiseSeen = (index) => {
    dispatch(advertise_seen_count_change({ advertise: location?.state?.movie?.advertise[index]?.advertise?.id, category_type: location?.state?.movie?.Data?.category_type, movie_id: location?.state?.movie?.Data?.id }))

  }

  const handleChangeMovie = async () => {
    const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

    if (nextMovie) {
      nextMovie.remove();
    }
    let obj = {
      movie: movieData?.Data?.id,
      status: "Stop"
    }

    const send_status = await live_movie_view_count(obj)
    const res = await axios.get("https://api.ipify.org/?format=json");
    let userObj = {
      user_id: user_id,
      profile_id: profile_id,
      ip_address: res.data.ip

    }
    playerRef.current = undefined
    const resData = await getMovieDetails(movieData?.Related_Movie[0]?.slug, userObj)

    if (resData) {
      setMovieData(resData)
      setAdsFlag(resData?.advertise?.map((ele) => true) || [])

    }

    history.replace(ROUTES.video, { movie: resData, resume: "" })

  }
  const handlePlayerReady = (player) => {
    var currentTime = 0;
    playerRef.current = player;

    if (location?.state?.resume) {
      const [hours, minutes, seconds] = location?.state?.resume?.split(':');
      const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);

      setTimeout(() => {


        currentTime = totalSeconds
        player?.currentTime(totalSeconds)
        playerRef.current.currentTime(totalSeconds)
      }, 500);
    }



    player.controlBar.addChild('MenuButton', {
      className: 'audio_subtitle_resolution_controls',
      clickHandler: function () {
        if (!player.paused()) {
          player.play()
        }

      }

    })



    player?.addChild("QualitySelector")
    //  player.addChild("PlaybackRateMenuButton")
    player.addChild("fullscreenToggle")
    player.controlBar.removeChild("QualitySelector")
    //  player.controlBar.removeChild("PlaybackRateMenuButton")
    player.controlBar.removeChild("fullscreenToggle")
    player.titleBar.removeClass('vjs-hidden')
    // player.titleBar.addChild('button', {
    //   controlText: `${movieData?.Data?.movie_name}`,
    //   className: 'vjs-visible-text',
    //   position: 2,
    // });
    player.markers({
      markerStyle: {
        'position': 'absolute',
        'height': '4px',
        'width': '4px',
        'top': "0",
        'background-color': 'yellow'
      },
      markers: (adsFlag || [])?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        return { time: time }
      }

      )

    })

    player.addChild('button', {
      className: 'ads-icon'
    });
    player.addChild('button', {
      className: 'go-back',
      clickHandler: function (event) {
        history.replace(`${ROUTES.moviedetails}/${movieData?.Data?.slug}`)
      }
    });


    player.on("userinactive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "none"
      document.getElementsByClassName(" go-back")[0].style.display = "none"



    })
    player.on("useractive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "block"
      document.getElementsByClassName(" go-back")[0].style.display = "block"
    })

    player.ads();

    const [hours, minutes, seconds] = movieData.Data?.skip_start?.split(':') || [0, 0, 0];
    const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);

    const [hours1, minutes1, seconds1] = movieData.Data?.skip_end?.split(':') || [0, 0, 0];
    const endSkip = (+hours1) * 60 * 60 + (+minutes1) * 60 + (+seconds1);
    let skipFlag = true

    const [hours2, minutes2, seconds2] = movieData?.Data?.movie_duration.split(':') || [0, 0, 0];
    const episodeDuration = (+hours2) * 60 * 60 + (+minutes2) * 60 + (+seconds2);





    function initScratchCard(canvas, index) {
      const ctx = canvas.getContext('2d');
      const scratchcardImage = new Image();
      scratchcardImage.src = scratchcardimage; // Ensure 'scratchcardimage' is correctly defined with a valid path.

      scratchcardImage.onload = () => {
        // Initially draw the image on the canvas
        ctx.drawImage(scratchcardImage, 0, 0, canvas.width, canvas.height);

        let coveredArea = new Uint8ClampedArray(canvas.width * canvas.height);
        let scratchedPixels = 0;
        const totalPixels = canvas.width * canvas.height;
        const threshold = totalPixels * 0.55; // 55% threshold

        function updateScratchedPixels(x, y, radius) {
          const startX = Math.max(x - radius, 0);
          const startY = Math.max(y - radius, 0);
          const endX = Math.min(x + radius, canvas.width);
          const endY = Math.min(y + radius, canvas.height);

          for (let i = startY; i < endY; i++) {
            for (let j = startX; j < endX; j++) {
              if ((j - x) * (j - x) + (i - y) * (i - y) <= radius * radius) {
                const idx = i * canvas.width + j;
                if (!coveredArea[idx]) {
                  coveredArea[idx] = 1; // Mark this pixel as scratched
                  scratchedPixels++;
                }
              }
            }
          }

          if (scratchedPixels >= threshold) {
            displayGif();
          }
        }

        canvas.addEventListener('mousemove', function (e) {
          scratch(e);
        });

        canvas.addEventListener('touchmove', function (e) {
          scratch(e.touches[0]);
          e.preventDefault(); // Prevent the default touch action (scroll, zoom)
        }, { passive: false });

        function scratch(e) {
          const rect = canvas.getBoundingClientRect();
          const mouseX = e.clientX - rect.left;
          const mouseY = e.clientY - rect.top;
          ctx.globalCompositeOperation = 'destination-out';
          ctx.beginPath();
          ctx.arc(mouseX, mouseY, 30, 0, 2 * Math.PI);
          ctx.fill();
          updateScratchedPixels(mouseX, mouseY, 30);
        }
      };

      // Function to display the GIF as an img element
      function displayGif() {
        canvas.style.display = 'none';  // Hide the canvas

        const imgContainer = document.getElementById('gif-container');
        if (!imgContainer) {
          console.error('GIF container not found');
          return;
        }

        const gifElement = document.createElement('img');
        gifElement.src = scratchgif;
        gifElement.style.position = 'absolute';
        gifElement.style.top = '40px';
        gifElement.style.right = '40px';
        gifElement.style.height = '150px'; // Adjust based on your needs
        gifElement.style.width = '230px'; // Adjust based on your needs
        gifElement.style.borderRadius = '10px';
        gifElement.style.display = 'block'; // Ensure it is visible
        imgContainer.appendChild(gifElement);
        setTimeout(() => {
          gifElement.remove()
        }, 1500);
      }
    }





    const imagearrayforqr = [
      {
        start_time: "10",
        end_time: "20",
        image: image1,
        link: "https://www.hilton.com/en/hotels/tastchi-hilton-tashkent-city/",
        isshown: false
      },
      {
        start_time: "30",
        end_time: "40",
        image: image1,
        link: "https://www.hilton.com/en/hotels/tastchi-hilton-tashkent-city/",
        isshown: false
      },
      {
        start_time: "50",
        end_time: "60",
        image: image1,
        link: "https://www.hilton.com/en/hotels/tastchi-hilton-tashkent-city/",
        isshown: false
      }
    ]


    player.on('timeupdate', function () {

      const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

      const currentTime = Math.floor(player.currentTime());


      location?.state?.movie?.qr_details?.map((item, index) => {
        var hms = item?.qr_arrival_time;
        var a = hms.split(':');
        var arrival_seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (a[2] ? +a[2] : 0);
        const startTimeInt = parseInt(arrival_seconds);


        const endTimeInt = parseInt(startTimeInt + 15);

        if (currentTime >= startTimeInt && currentTime <= endTimeInt && true) {
          if (!document.getElementById(`qr-container-${index}`)) {
            // Create container for QR code and canvas
            const qrContainer = document.createElement('div');
            qrContainer.style.position = 'absolute';
            qrContainer.style.top = '40px';
            qrContainer.style.left = '80%';
            qrContainer.style.height = '190px';
            qrContainer.style.width = '195px';
            qrContainer.style.position = 'relative';
            qrContainer.style.right = '50px';
            qrContainer.id = `qr-container-${index}`;
            document.querySelector('.video-js').appendChild(qrContainer);

            // Add QR code  
            const qrImage = document.createElement('img');
            qrImage.src = IMAGE_ENDPOINT + item?.qr?.qr_img;
            qrImage.style.width = '100%';
            qrImage.style.height = '100%';
            qrImage.style.borderRadius = '20px';
            qrImage.style.position = 'absolute';
            qrContainer.appendChild(qrImage);

            // Add canvas for scratch effect
            // const canvas = document.createElement('canvas');
            // canvas.height = 150;
            // canvas.width = 230;
            // canvas.style.position = 'absolute';
            // qrContainer.appendChild(canvas);
            // initScratchCard(canvas);
            const button = document.createElement('button');
            button.textContent = 'click here';
            button.style.fontSize = '16px';
            button.style.width = '120px';
            button.style.position = 'absolute';
            button.style.top = '50%';
            button.style.left = '50%';
            button.style.padding = '15px 20px';
            button.style.borderRadius = '8px';
            button.style.cursor = 'pointer';
            button.style.transform = 'translate(-50%, -50%)';
            button.style.backgroundColor = '#f06922';
            qrContainer.appendChild(button);

            button.addEventListener('click', function () {
              button.style.visibility = 'hidden';

              const text = document.createElement('span');
              text.textContent = "QR Saved In History";
              text.style.fontSize = "19px";
              text.style.color = "white";
              text.style.fontWeight = "bold";
              text.style.backgroundColor = "black";
              text.style.borderRadius = "10px";
              text.style.position = 'absolute';
              text.style.padding = '8px';
              text.style.top = '0%';
              text.style.left = '0%';
              text.style.transform = 'translate(-50%, -50%)';
              qrContainer.appendChild(text);

              setTimeout(function () {
                qrContainer.removeChild(text);
              }, 3000);


              const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
              const obj = {
                user_id: user_id,
                qr_id: item?.qr?.id,
                expire_date: item?.qr?.qr_end_date
              }

              const resData = qrredeemhistory(obj)

            });


            item.isshown = true;
          }
        } else if (currentTime > endTimeInt && item.isshown) {
          const existingContainer = document.getElementById(`qr-container-${index}`);
          if (existingContainer) {
            existingContainer.remove();
          }
          item.isshown = false;
        }
      });









      if (location?.state?.movie?.is_subscription_expiry && location?.state?.movie?.Data?.movie_access == "SVOD") {
        const [hours, minutes, seconds] = location?.state?.movie?.free_preview_time?.split(':') || [0, 0, 0];
        const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
        player.controlBar.progressControl.addClass("vjs-hidden")

        player.controlBar.skipForward.addClass("vjs-hidden")
        player.controlBar.skipBackward.addClass("vjs-hidden")

        const remaining = totalSeconds - player?.currentTime()

        const r_minutes = Math.floor(remaining / 60);
        const r_seconds = Math.floor(remaining % 60);

        // console.log("here this is", location?.state?.movie?.free_preview_time);
        if (player?.currentTime() >= totalSeconds) {
          setRemainingMin(true)
          Setopen(true)
          player.pause();
          // alert("please subscribe")
          // history.push(ROUTES.moviedetails + "/" + location?.state?.movie?.Data?.slug)
        }
      }



      if (parseInt(player?.currentTime()) >= totalSeconds && parseInt(player?.currentTime()) <= endSkip && skipFlag) {
        player.addChild('button', {
          className: 'skip-intro',
          id: 'skip-intoduction',
          clickHandler: function (event) {
            player?.currentTime(endSkip)
          }
        })
        skipFlag = false
      }
      else if ((parseInt(player?.currentTime()) < totalSeconds || parseInt(player?.currentTime()) > endSkip) && !skipFlag) {
        // const skipButton = player.getChild('button.skip-intro');
        const skipButton = document.getElementsByClassName('skip-intro')[0];

        if (skipButton) {
          skipButton.remove();
          skipFlag = true
        }


      }

      // if (parseInt(player?.currentTime()) >= (episodeDuration - 10)) {
      //   const thumbnailButton = document.createElement('div');
      //   thumbnailButton.className = 'recomendedMovies-player';
      //   thumbnailButton.style.backgroundImage = `url(${IMAGE_ENDPOINT}/${movieData.Related_Movie[0]?.movie_poster})`;
      //   // thumbnailButton.addEventListener('click', () =>
      //   //   handleChangeMovie()
      //   // );

      //   player.addChild('button', {
      //     el: thumbnailButton,
      //     clickHandler: function (event) {
      //       handleChangeMovie()
      //       const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

      //       if (nextMovie) {
      //         nextMovie.remove();
      //       }
      //     }
      //   });
      // }
      else {
        const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

        if (nextMovie) {
          nextMovie.remove();
        }
      }

      adsFlag?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        if (Math.round(player?.currentTime()) >= time && ele) {

          player.trigger('adsready');
          player.controlBar.progressControl.disable()

          player.controlBar.skipForward.addClass("vjs-hidden")
          player.controlBar.skipBackward.addClass("vjs-hidden")
          document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "yellow"
          document.getElementsByClassName("ads-icon")[0].style.display = "block"
          var tiles = document.getElementsByClassName("vjs-marker")
          for (var i = 0; i < tiles.length; i++) {
            tiles[i].style.backgroundColor = "transparent";

          }
        }
      })

    });

    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode();
      // play your linear ad content
      // in this example, we use a static mp4
      for (let index = 0; index < adsFlag.length; index++) {
        const element = adsFlag[index];
        if (element) {
          player.src(movieData.advertise[index]?.advertise?.advertise_url);
          handleAdvertiseSeen(index)

          break
        }

      }

      player.options.controls = false;
      // send event when ad is playing to remove loading spinner
      player.on('adplaying', function () {
        player.trigger('ads-ad-started');
      });

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode();
        for (let index = 0; index < adsFlag.length; index++) {
          const element = adsFlag[index];
          if (element) {
            const temp = adsFlag
            temp[index] = false
            setAdsFlag([...temp])
            break
          }

        }
        player.controlBar.progressControl.enable()
        player.controlBar.skipForward.removeClass("vjs-hidden")
        player.controlBar.skipBackward.removeClass("vjs-hidden")
        document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "red"
        document.getElementsByClassName("ads-icon")[0].style.display = "none"
        // player.src(videoJsOptions.sources)

        var tiles = document.getElementsByClassName("vjs-marker")
        for (var i = 0; i < tiles.length; i++) {
          tiles[i].style.backgroundColor = "yellow";

        }

      });
    });
    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    window.addEventListener('beforeunload', async (event) => {
      event.preventDefault();
      // if (isLoggedIn && profile_id && user_id && playerRef && running_status?.statuscode == 200) {
      if (isLoggedIn && profile_id && user_id && playerRef) {

        let time = new Date(parseInt(playerRef?.current?.currentTime()) * 1000)
          .toISOString()
          .slice(11, 19);
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }
        event.returnValue = await addContinueWatching(userObj);

      }
    });

    player.on('dispose', async () => {
      // let currentTime = player.currentTime();
      let time = new Date(parseInt(player?.currentTime()) * 1000)
        .toISOString()
        .slice(11, 19);
      videojs.log('player will dispose', time);
      if (isLoggedIn && profile_id && user_id) {
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }

        const res = await axios.get("https://api.ipify.org/?format=json");
        if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
          const resData = await addContinueWatching(userObj, true)
          // const status = await change_running_status({
          //   user: user_id,
          //   login_type: "Web",
          //   running_status: "Stop",
          //   ip_address: res.data.ip,
          //   movie_id: location?.state?.movie?.Data?.id,
          //   episode_id: "",
          //   content_type: location?.state?.movie?.category_type
          // })
        }
        else {
          const resData = await addContinueWatching(userObj, false)

        }


        // let obj = {
        //   movie: movieData?.Data?.id,
        //   status: "Stop"
        // }

        // const send_status = await live_movie_view_count(obj)

      }
    });
    player.on("seeking", () => {

      currentTime = player?.currentTime()
    })

    // player.on('ended', function () {
    //   const thumbnailButton = document.createElement('div');
    //   thumbnailButton.className = 'recomendedMovies-player';
    //   thumbnailButton.style.backgroundImage = `url(http://122.170.109.73:5554/OTT/static/Promotion/bhediya-16x9-1687776267402.jpg)`;
    //   thumbnailButton.addEventListener('click', () => {

    //   });

    //   player.addChild('button', {
    //     el: thumbnailButton,
    //   });

    // });

    player.on('canplay', () => {
      if (player?.currentTime() != currentTime)
        player?.currentTime(currentTime)

    })
    player.on("loadeddata", function () {


      let QualitySelector = document.getElementsByClassName("vjs-quality-selector")[0]

      let AudioButton = document.getElementsByClassName("vjs-audio-button")[0]

      QualitySelector.classList.remove("vjs-hidden")
      QualitySelector.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      QualitySelector.getElementsByClassName("vjs-menu")[0].classList.add("always-open")

      AudioButton.classList.remove("vjs-hidden")
      AudioButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      AudioButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")

      try {
        const li1 = document.createElement("li");
        li1.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
        li1.appendChild(QualitySelector);

        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li1)
      }
      catch (error) {
        console.log(error, "li1")
      }
      try {
        const li2 = document.createElement("li");
        li2.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li li-second");
        li2.appendChild(AudioButton);
        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li2)
      }
      catch (error) {
        console.log(error, "li2")
      }

      let SubsCapsButton = document.getElementsByClassName("vjs-subs-caps-button")[0]
      SubsCapsButton.classList.remove("vjs-hidden")
      SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
      SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")
      try {
        const li3 = document.createElement("li");
        li3.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
        li3.appendChild(SubsCapsButton);

        document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li3)
      }
      catch (error) {
        console.log(error, "li3")
      }

      var qualitySelectorItems = document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul");
      for (var i = 0; i < qualitySelectorItems.length; i++) {

        qualitySelectorItems[i].addEventListener("click", function (event) {
          event.stopPropagation()
          document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("button")[0].click()

        });

      }
      var lis = document.getElementsByClassName("custom-li")
      for (var i = 0; i < lis.length; i++) {

        if (lis[i].children.length <= 0) {

          lis[i].remove()
        }
      }
      var lis = document.getElementsByClassName("li-second")
      for (var i = 0; i < lis.length; i++) {

        if (lis[i].children.length <= 0) {

          lis[i].remove()
        }
      }



    })




    player.on("qualityRequested", () => {
      if (player.play()) {
        player.play()
      }
      const temp = document.getElementsByClassName("vjs-menu-item vjs-selected")[3].getElementsByClassName("vjs-menu-item-text")[0].innerText
      player.src(videoJsOptions.sources.find((ele) => ele?.label == temp))
      for (let i = 0; i < videoJsOptions.sources.length; i++) {
        videoJsOptions.sources[i].selected = (videoJsOptions.sources[i]?.label == temp)
      }
      currentTime = player?.currentTime()
      player.load();

    })


  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    history.goBack()
    dispatch({ type: RUNNING_STATUS, payload: null })
  };

  useMemo(() => {
    if (running_status?.statuscode == 406) {
      setPopupOpen(true)



    }
  }, [running_status])



  const getCurrentTime = () => {
    if (playerRef.current) {
      return Math.floor(playerRef.current.currentTime());
    }
    return 0; // Or any default value if playerRef is not available
  };


  return (
    <>
      {/* <div id="gif-container"></div> */}
      <Modal
        open={popupOpen}
        onClose={handlePopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              {running_status?.message}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={handlePopupClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center", marginBottom: "1rem" }}>
              {/* {running_status?.message} */}
              Subscribe Now To Continue
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={handleClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                Close
              </button>

              <NavLink to={ROUTES.priceplan}>
                <button className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                  Subscribe
                </button>
              </NavLink>

            </div>
          </div>
        </Box>
      </Modal>

      {!popupOpen && <div className='video-parent video'>
        <VideoJS playerRef={playerRef} options={videoJsOptions} onReady={handlePlayerReady} />
        {
          location?.state?.movie?.Data?.movie_access == "SVOD" && !remainingMin && location?.state?.movie?.is_subscription_expiry !== false ?
            <div style={{ position: "absolute", top: "95px", left: "10px" }}>
              <VideoFile123 data={location?.state?.movie?.free_preview_time} getCurrentTime={getCurrentTime} />
            </div>
            :
            <></>
        }

      </div>}
      {/* <div onClick={() => history.goBack()} className='go-back' ><img src={left_arrow} width={"40px"}/></div> */}

    </>
  )
}


