import { ADD_CONTINUE_WATCHING, ADD_CONTINUE_WATCHING_ERROR, ADD_MOVIE_IN_WATCHLIST, ADD_MOVIE_IN_WATCHLIST_ERROR, GET_ALL_GENRE, GET_ALL_HIGHLIGHTS_DATA, GET_ALL_LANGUAGE, GET_ALL_MOVIES, GET_ALL_MOVIES_BY_GENRE, GET_ALL_MOVIES_BY_GENRE_ERROR, GET_ALL_MOVIES_BY_LANGUAGE, GET_ALL_MOVIES_BY_LANGUAGE_ERROR, GET_ALL_MOVIES_ERROR, GET_ALL_SERIES, GET_ALL_SERIES_ERROR, GET_ALL_SUBCATEGORY, GET_ALL_UPCOMING_MOVIES, GET_COIN_DETAILS, GET_COIN_DETAILS_ERROR, GET_CONTINUE_WATCHING, GET_CONTINUE_WATCHING_ERROR, GET_LEAVING_SOON_MOVIES, GET_LEAVING_SOON_MOVIES_ERROR, GET_MOVIE_DETAILS, GET_MOVIE_DETAILS_ERROR, GET_PROMOTION_DETAIL, GET_QR_DETAIL, GET_QR_REDEEM_DATA, GET_RECOMMENDED_MOVIES, GET_RECOMMENDED_MOVIES_ERROR, GET_SLIDER_DATA, GET_SLIDER_DATA_ERROR, GET_TVOD_MOVIE, GET_UPCOMING_MOVIES, GET_UPCOMING_MOVIES_ERROR, GET_USER_REDEEM_HISTORY, GET_USER_WATCHLIST, GET_USER_WATCHLIST_ERROR } from "../../Actiontype/ActionType"
const initstate = {
    recommended: null,
    addcontinueWatching: null,
    continueWatching: null,
    series: null,
    addWatchlist: null,
    watchlist: null,
    movies: null,
    tvodmovies: null,
    movieByLanguage: null,
    movieByGenre: null,
    leavingSoon: null,
    coin: null,
    promotion: null,
    subcategory: null,
    genre: null,
    language: null,
    qrdetail:null,
    highlights:null,
    redeemdata:null,
    redeemhistory:null,
    upcoming:null
}

const MovieStore = (state = initstate, action) => {

    const { type, payload } = action

    switch (type) {

        case GET_USER_WATCHLIST: {
            return {
                ...state,
                watchlist: payload
            }
        }
        case GET_USER_REDEEM_HISTORY: {
            return {
                ...state,
                redeemhistory: payload
            }
        }
        case GET_QR_REDEEM_DATA: {
            return {
                ...state,
                redeemdata: payload
            }
        }
        case GET_ALL_HIGHLIGHTS_DATA: {
            return {
                ...state,
                highlights: payload
            }
        }
        case GET_QR_DETAIL: {
            return {
                ...state,
                qrdetail: payload
            }
        }
        case GET_USER_WATCHLIST_ERROR: {

            return {
                ...state,
                watchlist: null
            }
        }

        case GET_ALL_MOVIES: {
            return {
                ...state,
                movies: payload
            }
        }

        case GET_ALL_UPCOMING_MOVIES: {
            
            return {
                ...state,
                upcoming: payload
            }
        }
        case GET_PROMOTION_DETAIL: {
            return {
                ...state,
                promotion: payload
            }
        }
        case GET_ALL_SUBCATEGORY: {
            return {
                ...state,
                subcategory: payload
            }

        }
        case GET_ALL_GENRE: {
            return {
                ...state,
                genre: payload
            }
        }
        case GET_ALL_LANGUAGE: {
            return {
                ...state,
                language: payload
            }
        }
        case GET_TVOD_MOVIE: {
            return {
                ...state,
                tvodmovies: payload
            }
        }
        case GET_ALL_MOVIES_ERROR: {

            return {
                ...state,
                movies: null
            }
        }

        case GET_ALL_SERIES: {
            return {
                ...state,
                series: payload
            }
        }
        case GET_ALL_SERIES_ERROR: {

            return {
                ...state,
                series: null
            }
        }
        case GET_COIN_DETAILS: {
            return {
                ...state,
                coin: payload
            }
        }
        case GET_COIN_DETAILS_ERROR: {

            return {
                ...state,
                coin: null
            }
        }

        case GET_CONTINUE_WATCHING: {
            return {
                ...state,
                continueWatching: payload
            }
        }
        case GET_CONTINUE_WATCHING_ERROR: {

            return {
                ...state,
                continueWatching: null
            }
        }

        case GET_ALL_MOVIES_BY_LANGUAGE: {
            return {
                ...state,
                movieByLanguage: payload
            }
        }
        case GET_ALL_MOVIES_BY_LANGUAGE_ERROR: {

            return {
                ...state,
                movieByLanguage: null
            }
        }
        case GET_ALL_MOVIES_BY_GENRE: {
            console.log("in store ", payload)

            return {
                ...state,
                movieByGenre: payload
            }
        }
        case GET_ALL_MOVIES_BY_GENRE_ERROR: {

            return {
                ...state,
                movieByGenre: null
            }
        }

        case ADD_MOVIE_IN_WATCHLIST: {
            return {
                ...state,
                addWatchlist: payload
            }
        }
        case ADD_MOVIE_IN_WATCHLIST_ERROR: {

            return {
                ...state,
                addWatchlist: null
            }
        }

        case GET_RECOMMENDED_MOVIES: {
            return {
                ...state,
                recommended: payload
            }
        }
        case GET_RECOMMENDED_MOVIES_ERROR: {

            return {
                ...state,
                recommended: null
            }
        }

        case ADD_CONTINUE_WATCHING: {
            return {
                ...state,
                addcontinueWatching: payload
            }
        }
        case ADD_CONTINUE_WATCHING_ERROR: {

            return {
                ...state,
                addcontinueWatching: null
            }
        }
        case GET_LEAVING_SOON_MOVIES: {
            return {
                ...state,
                leavingSoon: payload
            }
        }
        case GET_LEAVING_SOON_MOVIES_ERROR: {

            return {
                ...state,
                leavingSoon: null
            }
        }
        case GET_UPCOMING_MOVIES: {
            return {
                ...state,
                upcomingMovies: payload
            }
        }
        case GET_UPCOMING_MOVIES_ERROR: {

            return {
                ...state,
                upcomingMovies: null
            }
        }

        default:
            return state
    }

}


export default MovieStore