import axios from 'axios'
import React, { useState } from 'react'
import { load } from '@cashfreepayments/cashfree-js'

const Blog = () => {

    const [orderId, setOrderId] = useState(null)
    let ORDER = ""
    let cashfree;
    let initializeSDK = async function () {
        cashfree = await load({
            // mode: "production"
            mode: "sandbox"

        })
    }
    initializeSDK()

    const getSessionId = async () => {
        try {
            let res = await axios.get("http://localhost:5000/payment")
            if (res?.data && res?.data?.payment_session_id && res?.data?.order_id) {
                console.log(res.data)
                ORDER = res?.data?.order_id
                setOrderId(res?.data?.order_id)
                return res.data.payment_session_id
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verifyPayment = async () => {
        try {
            let res = await axios.post("http://localhost:5000/verify", { orderId: orderId || ORDER })
            if (res && res.data) {
                console.log("resuklt", res)
                if (res?.data?.[0]?.payment_status == "SUCCESS") {
                    alert("Payment success")
                }
                else {
                    alert("Something went wrong")

                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async (e) => {
        e.preventDefault()
        try {
            let sessionId = await getSessionId()
            let checkoutOptions = {
                paymentSessionId: sessionId,
                redirectTarget: "_modal",
            }

            cashfree.checkout(checkoutOptions).then((res) => {
                console.log("payment initialize", res)
                // setTimeout(()=>{
                verifyPayment(orderId)

                // },1500)
            })



        } catch (error) {
            console.log(error)
        }
    }

    const handleClickPython = async () => {
        try {
            let res = await axios.post("https://api.gujjustream.com/api/payment_initiate/")
            console.log("python", res)
            if (res?.data?.statuscode == 200) {
                let sessionId = await res?.data?.data?.payment_session_id
                setOrderId(res?.data?.data?.order_id)
                ORDER = res?.data?.data?.order_id
                console.log("order id", res?.data?.data?.order_id)
                let checkoutOptions = {
                    paymentSessionId: sessionId,
                    redirectTarget: "_modal",
                }

                cashfree.checkout(checkoutOptions).then(async (result) => {
                    console.log("payment initialize", result)
                    let resData = await axios.post("https://api.gujjustream.com/api/payment_verify/", { order_id: orderId || ORDER })
                    console.log("verify status", resData)

                })



            }

        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className='m-profile'>
                <button onClick={handleClick}>
                    Pay now with nodejs
                </button><br /><br />

                <button onClick={handleClickPython}>
                    Pay now with python
                </button>
            </div>
        </>
    )
}

export default Blog