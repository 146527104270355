//router
import LayoutsRoute from './router/layouts-route';

//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'
import { useEffect, useState } from 'react';
import Maintanance from './views/backend/pages/Maintanance';
import * as HomeAction from './actions/HomeActions'

function App() {
  const [maintanace, setMaintanance] = useState(null)
  const { getMaintananceMode } = HomeAction

  useEffect(() => {
    (async () => {
      const resData = await getMaintananceMode()
      if (resData?.statuscode == 200) {
        setMaintanance(resData?.web_maintenance_mode)
      }
    })();
  }, [])
  return (
    <div className="App">
      {
        !maintanace ?
          <LayoutsRoute />
          :
          <Maintanance />
      }
    </div>
  );
}

export default App;
