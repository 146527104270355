import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MovieAction from '../../../actions/MovieAction'
import axios from 'axios';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { Swiper, SwiperSlide } from 'swiper/react';
import gujjuCoin from '../../../assets/regal_images/coin.png'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import { Base64 } from 'js-base64';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


export default function LeavingSoon() {
    const dispatch = useDispatch()
    const { getLeavingSoonMovies } = bindActionCreators(MovieAction, dispatch)
    const [movies, setMovies] = useState([])
    useEffect(() => {
        (async () => {
            const res = await axios.get("https://api.ipify.org/?format=json");
            let useObj = {
                ip_address: res.data.ip
            }
            const resData = await getLeavingSoonMovies(useObj)
            console.log(resData)
            // setMovies(resData)
            setMovies({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })

        })()
    }, [])
    return (
        <div>

            {
                movies.data?.length ?
                    <div className='main-content'>
                        <section id="iq-favorites">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="main-title btn-color" style={{ textTransform: "uppercase" }}>Leaving soon</h6>
                                            {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                                        </div>
                                        <div id="favorites-contens">
                                            {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                            <Swiper
                                                navigation={{
                                                    prevEl: '#prev',
                                                    nextEl: '#next'
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 2, spaceBetween: 12 },
                                                    450: { slidesPerView: 3 },
                                                    638: { slidesPerView: 3 },
                                                    810: { slidesPerView: 4 },
                                                    1048: { slidesPerView: 5 },
                                                    1300: { slidesPerView: 6 },
                                                    1600: { slidesPerView: 7, spaceBetween: 9 },
                                                    1650: { slidesPerView: 8 },
                                                    2050: { slidesPerView: 9 },
                                                    2230: { slidesPerView: 10 },
                                                    2415: { slidesPerView: 11 },
                                                }}
                                                // loop={(movies?.length > 4) ? true : false}
                                                loop={false}
                                                slidesPerView={6}
                                                spaceBetween={12}
                                                as="ul"
                                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                                {
                                                    movies.data?.map((data, index) => (
                                                        <SwiperSlide as="li" key={index}>
                                                            <div className="thumbnail-container">
                                                                <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                                    <div className='abcd'>
                                                                        <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img1`} alt={data?.movie_name} />
                                                                    </div>
                                                                </Link>
                                                                <div className='hover-class1'>
                                                                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                                        <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#f06922" }} />
                                                                        <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                                    </div>
                                                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                                        {data.movie_name}
                                                                    </div>
                                                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                                        <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                                        {" " + data?.movie_coin} Coins
                                                                    </div>
                                                                    <span style={{ color: "white", fontSize: "0.8rem", marginTop: "8px" }}>Duration : {data?.movie_duration}
                                                                    </span>
                                                                    <span style={{ color: "white", fontSize: "0.8rem", marginTop: "1px" }}> Gentre : {data?.movie_genre[0]?.genre_title}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                }

                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>


                    </div>
                    :
                    <></>
            }
        </div>

    )
}
