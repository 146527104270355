import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Button, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// parallax
import parallax3 from '../../../assets/images/parallax/p1.jpg'
import parallax4 from '../../../assets/images/parallax/parallax-logo.png'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList'
import { ROUTES } from '../../../constant/routes'
import { useHistory } from 'react-router-dom'

import logo from '../../../assets/regal_images/regal_logo.png'
import * as MovieAction from '../../../actions/MovieAction'

import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

function MovieBanner(props) {
    const targetRef = useRef(null);
    const dispatch = useDispatch()

    const history = useHistory()
    const [popup18Plus, setPopup18Plus] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const { setCompleteHours } = MovieAction
    const [animation, setAnimation] = useState(false)
    const { webDashboardPromotion } = bindActionCreators(MovieAction, dispatch)
    const [promotionData, setPromotionData] = useState([])


    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id




    useEffect(() => {
        (async () => {

            const resData = await webDashboardPromotion({ profile_id: profile_id || null, user_id: user_id || null })
            if (resData?.data?.list_promotion?.[0]?.movie?.platform !== "App"){
                setPromotionData(resData?.data);

            }
            // console.log("resdata", resData?.data )
            // console.log("resdata1", promotionData)
            // let user = {
            //     user_id: user_id ? user_id : "null",
            //     profile_id: profile_id ? profile_id : "null"
            // }
            // setLanguage(Homedata?.data?.Language)
            // setGenre(Homedata?.data?.Genre)  
        })();
    }, [])

    // console.log("resdata", promotionData)


    useEffect(() => {
        const intervalId = setInterval(() => {
            setHoursLeft(calculateHoursLeft());
        }, 1000);
    }, [])
    const isRunningInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    useEffect(() => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.5, // Trigger the callback when 50% of the element is in view
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Call your function when the target div is in view
                    yourFunction();
                    // Stop observing to avoid multiple calls
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        // Cleanup observer when component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []); // Empty dependency array to run effect once on mount

    const yourFunction = () => {
        console.log('Target div is in view! Call your function here.');
        setAnimation(true)
        // Add your function logic here
    };


    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

    const handlePopup18PlusClose = () => {
        setPopup18Plus(false);
    };
    const handleRedirect = (type) => {
        if (type == "Series") {
            history.push(ROUTES.seriesDetails, { series: props?.promotion?.data[0]?.series?.id ? props?.promotion?.data[0]?.series?.id : "" })
        }
        else {
            history.push(`${ROUTES.moviedetails}/${props?.promotion?.data[0]?.movie?.slug}`)
        }
    }
    const releaseDate = new Date(props?.promotion?.data[0]?.release_date + "T" + props?.promotion?.data[0]?.publish_time + "Z")
    const handlePlayNow = () => {
        if (props?.promotion?.data[0]?.video_type == "Series") {
            history.push(ROUTES.seriesDetails, { series: props?.promotion?.data[0]?.series?.id ? props?.promotion?.data[0]?.series?.id : "" })
        }
        else {
            if (props?.promotion?.data[0]?.movie?.isDesktop == "true" && !isRunningInElectron) {
                history.push(`${ROUTES.moviedetails}/${props?.promotion?.data[0]?.movie?.slug}`)

            } else {
                if (props?.promotion?.data[0]?.movie?.movie_access == "TVOD" && props?.promotion?.data[0]?.get_status == "false") {
                    setHoursAlert(true)
                }
                else if (props?.promotion?.data[0]?.movie?.movie_access == "TVOD" && props?.promotion?.data[0]?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else {
                    if (props?.promotion?.data[0]?.movie?.movie_is_for18Plus == "Yes") {
                        if (!popup18Plus)
                            setPopup18Plus(true)
                        else
                            history.push(ROUTES.video, { movie: { ...props?.promotion?.data[0], Data: props?.promotion?.data[0]?.movie } })
                    }
                    else {
                        history.push(ROUTES.video, { movie: { ...props?.promotion?.data[0], Data: props?.promotion?.data[0]?.movie } })
                    }
                }



            }

            // if (props?.promotion?.data[0]?.movie?.movie_is_for18Plus == "Yes") {
            //     if (!popup18Plus)
            //         setPopup18Plus(true)


            //     else {
            //         history.push(ROUTES.video, { movie: { ...props?.promotion?.data[0], Data: props?.promotion?.data[0]?.movie } })
            //     }


            // }
            // else {
            //     history.push(ROUTES.video, { movie: { ...props?.promotion?.data[0], Data: props?.promotion?.data[0]?.movie } })

            // }
        }

    }

    const handleHoursDialogueClose = () => {
        setHoursAlert(false);
        setCompletedHour(false)
    };

    const handleHoursContinue = async () => {
        const send_status = await setCompleteHours({
            user: user_id,
            movie: props?.promotion?.data[0]?.movie?.id
        })
        if (send_status?.statuscode == 200) {
            (props?.promotion?.data[0]?.movie?.isDesktop == "true" && !isRunningInElectron) ?
                history.push(`${ROUTES.moviedetails}/${props?.promotion?.data[0]?.movie?.slug}`)
                :
                history.push(ROUTES.video, { movie: { ...props?.promotion?.data[0], Data: props?.promotion?.data[0]?.movie } })

        }

    }
    // console.log("first", props?.promotion?.data)
    return (
        <>
            <Modal
                open={popup18Plus}
                onClose={handlePopup18PlusClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "left", fontSize: "19px", color: "red", fontWeight: "900" }}>
                        {/* <img src={logo} width={"100px"} /> */}
                        Maturity Rating: 18+
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "center" }}>
                            May contain mature content, nudity, violence, foul language, substances.
                        </Typography>
                        <Typography id="modal-modal-title" sx={{ mt: 2, textAlign: "left" }}>
                            I CONFIRM THAT I AM 18 YEARS AND ABOVE
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <button onClick={handlePlayNow} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                                OK
                            </button>
                            <button onClick={handlePopup18PlusClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={hoursAlert}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Once you start watching this movie, you have 72 hours to finish it; if not, you must rent it again.
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>

                            <button onClick={handleHoursContinue} className={"btn btn-hover iq-button"}>
                                Watch Now
                            </button>
                            <button onClick={handleHoursDialogueClose} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={completedHour}
                onClose={(handleHoursDialogueClose)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            You have exceeded 72 hours of movie watching limitation , please rent now to continue                         </Typography>
                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                            <button onClick={(handleHoursDialogueClose)} className={"btn btn-hover ml-3 iq-button"}>
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {
                promotionData?.list_promotion?.length ?
                    <section id="parallex" className="parallax-window">
                        <Container fluid className="h-100">
                            <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                                <Col lg="4" className={`r-mb-23 ${animation ? "start-animation" : ""}`}>
                                    <div className="text-left">
                                        {/* <Link to={`${ROUTES.moviedetails}/${props?.promotion?.data[0]?.movie?.slug}`}> */}
                                        {/* <Link to={`${((props?.promotion?.data[0]?.video_type) == "Series") ? () => handleRedirect() : ROUTES.moviedetails + '/' + props?.promotion?.data[0]?.movie?.slug}`}> */}

                                        <div onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)}>
                                            {/* <h3>{((props?.promotion?.data[0]?.video_type)=="Series") ? props?.promotion?.data[0]?.series?.series_name : props?.promotion?.data[0]?.movie?.movie_name}</h3> */}
                                            {/* <img src={((props?.promotion?.data[0]?.video_type) == "Series") ? IMAGE_ENDPOINT + props?.promotion?.data[0]?.series?.series_logo : IMAGE_ENDPOINT + props?.promotion?.data[0]?.movie?.movie_logo} className="img-fluid" alt="bailey" /> */}
                                            <h4 className='trending-text big-title text-uppercase'>{((promotionData?.list_promotion[0]?.video_type) == "Series") ? promotionData?.list_promotion[0]?.series?.series_name : promotionData?.list_promotion[0]?.movie?.movie_name}</h4>
                                            <div className="movie-time d-flex align-items-center mb-3 iq-ltr-direction mt-4">
                                                <h6 className="text-white">Duration : {promotionData?.list_promotion[0]?.movie?.movie_duration ? promotionData?.list_promotion[0]?.movie?.movie_duration : ""}</h6>
                                            </div>

                                            <p style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: (promotionData?.list_promotion[0]?.video_type) == "Series" ? promotionData?.list_promotion[0]?.series?.series_description : promotionData?.list_promotion[0]?.movie?.movie_description }}></p>
                                            <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                <p style={{ fontSize: "17px" }}>
                                                    <b>Language: </b><span>{((promotionData?.list_promotion[0]?.video_type) == "Series") ? promotionData?.list_promotion[0]?.series?.language?.language_name : promotionData?.list_promotion[0]?.movie?.movie_language}</span>
                                                </p>
                                                {/* <p>
                                                <b>Genres:</b> {data?.movie?.movie_genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                            </p> */}
                                                <p style={{ fontSize: "17px" }}>
                                                    <b>Genres: </b><span>{((promotionData?.list_promotion[0]?.video_type) == "Series") ? promotionData?.list_promotion[0]?.series?.genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data?.genre_title}</span>)) : promotionData?.list_promotion[0]?.movie?.movie_genre?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data?.genre_title}</span>))}</span>
                                                </p>
                                                <p>
                                                    {/* <b>Starring:</b> {data?.movie?.movie_cast?.map((data, index) => (<span key={index}>{(index ? ', ' : '') + data.cast_name}</span>))} */}
                                                </p>
                                            </div>
                                        </div>
                                        {/* </Link> */}

                                        <div className="parallax-buttons">
                                            {promotionData?.list_promotion[0]?.movie?.publish_type == "Later" && new Date() < releaseDate ?
                                                <p className='upcoming-p' >RELEASING ON <br /><span >{releaseDate.toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}&nbsp;|&nbsp;{releaseDate.toLocaleTimeString('en-IN', { timeZone: 'Europe/London', hour: 'numeric', minute: "2-digit", hour12: true })}</span></p>
                                                :
                                                <div>
                                                    {(promotionData?.list_promotion[0]?.movie?.movie_access == "SVOD" || promotionData?.list_promotion[0]?.series?.series_access == "SVOD") &&
                                                        (promotionData?.list_promotion[0]?.is_subscription_expiry ?
                                                            <Button className="btn btn-hover iq-button" onClick={() => history.push("/price-plan")}>
                                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>Subscribe Now
                                                            </Button> :

                                                            <Button className="btn btn-hover iq-button"
                                                                onClick={handlePlayNow}
                                                            >
                                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                            </Button>)
                                                    }
                                                    {(promotionData?.list_promotion[0]?.movie?.movie_access == "FREE" || promotionData?.list_promotion[0]?.series?.series_access == "FREE") &&
                                                        <Button className="btn btn-hover iq-button"
                                                            onClick={handlePlayNow}
                                                        >
                                                            <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                        </Button>
                                                    }

                                                    {(promotionData?.list_promotion[0]?.movie?.movie_access == "TVOD" || promotionData?.list_promotion[0]?.series?.series_access == "TVOD") &&
                                                        (promotionData?.list_promotion[0]?.is_rental_movie_expiry ?
                                                            <>
                                                                {/* <button className={"btn btn-hover iq-button"} onClick={() => history.push("/price-plan", { transactionType: "TVOD", movie: promotionData?.list_promotion[0]?.movie?.id, categoryType: promotionData?.list_promotion[0]?.video_type, price: promotionData?.list_promotion[0]?.movie?.rental_price || promotionData?.list_promotion[0]?.series?.rental_price, ads: promotionData?.list_promotion[0]?.advertise })}>Rent Now : ${Number(promotionData?.list_promotion[0]?.movie?.rental_price || promotionData?.list_promotion[0]?.series?.rental_price).toFixed(2)}</button> */}
                                                                <button className={"btn btn-hover iq-button"} onClick={() => history.push("/price-plan", { transactionType: "TVOD", redirect: ROUTES.moviedetails + '/' + promotionData?.list_promotion[0]?.movie?.slug, available_for: promotionData?.list_promotion[0]?.movie?.available_for_days, movieName: promotionData?.list_promotion[0]?.movie?.movie_name, movie: promotionData?.list_promotion[0]?.movie?.id, categoryType: "Movie", price: promotionData?.list_promotion[0]?.movie?.rental_price_with_ad, adfreePrice: promotionData?.list_promotion[0]?.movie?.rental_price, ads: promotionData?.list_promotion[0]?.advertise })}>Rent Now : ${Number(promotionData?.list_promotion[0]?.advertise?.length ? promotionData?.list_promotion[0]?.movie?.rental_price_with_ad : promotionData?.list_promotion[0]?.movie?.rental_price).toFixed(2)}</button>

                                                                {/* <RazorpayButton className={"btn btn-hover iq-button"} title={"Rent Now : $"+Number(promotionData?.list_promotion[0]?.rental_price).toFixed(2)} forceRefresh={setSave} transactionType={"TVOD"} movie={promotionData?.list_promotion[0]?.id} categoryType={"Movie"} price={promotionData?.list_promotion[0]?.rental_price}/> */}
                                                            </>
                                                            :
                                                            <div >
                                                                <Button className="btn btn-hover iq-button"
                                                                    onClick={handlePlayNow}
                                                                >
                                                                    <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now

                                                                </Button>
                                                                {/* <span style={{ fontSize: "12px", display: "flex", justifyContent: "center", marginTop: ".3rem", textTransform: "none" }}>{promotionData?.list_promotion[0]?.days_total} {promotionData?.list_promotion[0]?.days_total > 1 ? "days" : "day"} remaining</span> */}
                                                                <span style={{ fontSize: "12px", display: "flex", justifyContent: "center", marginTop: ".3rem", textTransform: "none" }}>{promotionData?.list_promotion[0]?.days_total != 0 ? promotionData?.list_promotion[0]?.days_total : ""} {promotionData?.list_promotion[0]?.days_total > 1 ? "days" : promotionData?.list_promotion[0]?.days_total == 0 ? `${hoursLeft} hours` : "day"} remaining</span>

                                                            </div>

                                                        )}
                                                </div>}
                                            {/* <Link className="btn btn-hover iq-button" to={`${ROUTES.moviedetails}/${promotionData?.list_promotion[0]?.movie?.slug}`}>
                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                            </Link> */}
                                            <div className="btn iq-button" onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)} >
                                                {/* <i className="fa fa-share icon-border ml-2"></i> */}
                                                <div className='icon-border-details ml-1'>
                                                    <i className="fa fa-info details-con" aria-hidden="true"></i>  Details

                                                </div>
                                            </div>
                                            {/* <Link to="/movie-details" className="btn btn-link">More details</Link> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="8">
                                    <div onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)}>

                                        <div ref={targetRef} id='img-content' className={`parallax-img ${animation ? "start-animation" : ""}`}>
                                            <img src={`${IMAGE_ENDPOINT + promotionData?.list_promotion[0]?.promotion_image_web}`} className="promo-img" alt="bailey" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section >
                    :
                    <></>
            }

        </>
    )
}

export default MovieBanner
