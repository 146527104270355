export const ROUTES = {
    home: '/',
    series: '/series',
    seriesDetails: '/series-details',
    contact: '/contact-us',
    moviedetails: '/movie-details',
    signup: '/sign-up',
    profile: '/profile',
    profile_edit: "/profile_edit",
    profile_selection: "/profile_selection",
    complaint: "/complaint",
    contactus: "/contactus",
    my_account: "/my_account",
    device_management: "/device_management",
    edit_account: "/edit_account",
    transaction_history: "/transaction_history",
    change_password: "/change_password",
    forgot_password: "/forgot_password",
    priceplan: '/price-plan',
    watchlist: '/watchlist',
    video: '/video',
    stream: '/stream',
    moviesByLanguage: '/selected-language-movies',
    moviesByGenre: '/selected-genre-movies',
    movies: '/movies',
    upcoming: '/upcoming',
    shortfilms: '/short-films',
    trailer: '/trailer',
    episode: '/series-episode',
    logoutwithOTP: '/logout-otp',
    search: "/search",
    rewards: "/rewards",
    qrdetail: "/qrdetail",
    vod: "/vod",
    stripePayment: '/stripe-payment',
    firebaseotp: "/firebaseotp",
    demandMovie: "/demand-movie"
};