import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import fav1 from '../../../assets/images/favorite/01.jpg'
import fav2 from '../../../assets/images/favorite/02.jpg'
import fav3 from '../../../assets/images/favorite/03.jpg'
import fav4 from '../../../assets/images/favorite/04.png'
import gujjuCoin from '../../../assets/regal_images/coin.png'
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import { Base64 } from 'js-base64';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export default function RecommendedMovies() {
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))

  const dispatch = useDispatch()
  const { getRecommendedMovies } = bindActionCreators(MovieAction, dispatch)
  const [movie, setMovies] = useState("")
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        videoRef.current.mute();

      } else {
        videoRef.current.play();
        videoRef.current.mute();

      }
      setIsPlaying(!isPlaying);
    }
  };


  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const resData = await getRecommendedMovies({ user_id: user_id, profile_id: profile_id })
        if (resData?.data?.length) {
          setMovies({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })
        }
      }

    })();
  }, [])


  return (
    <>
      {
        isLoggedIn && movie?.data?.length ?
          <div className='main-content'>
            <section id="iq-favorites">
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className={"main-title "} style={{ textTransform: "uppercase" }}>Because you watched  <span style={{ fontSize: "18px" }} className='btn-color'>"{movie?.movie_name}"</span></h6>
                    </div>
                    <div id="favorites-contens">
                      {/* <div id="prev4" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next4" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                      <Swiper
                        navigation={{
                          prevEl: '#prev',
                          nextEl: '#next'
                        }}
                        breakpoints={{
                          320: { slidesPerView: 2, spaceBetween: 12 },
                          450: { slidesPerView: 3 },
                          638: { slidesPerView: 3 },
                          810: { slidesPerView: 4 },
                          1048: { slidesPerView: 5 },
                          1300: { slidesPerView: 6 },
                          1600: { slidesPerView: 7, spaceBetween: 9 },
                          1650: { slidesPerView: 8 },
                          2050: { slidesPerView: 9 },
                          2230: { slidesPerView: 10 },
                          2415: { slidesPerView: 11 },
                        }}
                        loop={false}
                        slidesPerView={6}
                        spaceBetween={10}
                        as="ul"
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                        {
                          movie?.data?.length ?
                            movie?.data?.map((data, index) => (
                              <SwiperSlide as="li" key={index}>
                                <div className="thumbnail-container">
                                  <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                    <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img1`} alt={data?.movie_name} />
                                    {
                                      data?.leaving_soon === "true" && <div className='leaving-soon'>
                                        Leaving soon
                                      </div>
                                    }
                                  </Link>
                                  <div className='hover-class1'>
                                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                      <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#f06922" }} />
                                      <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                    </div>
                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                      {data.movie_name}
                                    </div>
                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                      <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                      {" " + data?.movie_coin} Coins
                                    </div>
                                    <span style={{ color: "white", fontSize: "0.8rem", marginTop: "8px" }}>Duration : {data?.movie_duration}
                                    </span>
                                    <span style={{ color: "white", fontSize: "0.8rem", marginTop: "1px" }}> Gentre : {data?.movie_genre[0]?.genre_title}
                                    </span>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))
                            :
                            <></>
                        }


                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
          :
          <></>
      }

    </>
  )
}
